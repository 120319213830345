// JS
require("@rails/ujs").start()
require("@rails/activestorage").start()
require("trix")
require("@rails/actiontext")
import "@hotwired/turbo-rails"

import "chartkick/chart.js"

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.

// Font Awesome
import "./src/fontawesome"

// Stimulus
import "./controllers"

document.addEventListener('turbo:before-visit', () => {
  Stimulus.controllers.forEach(controller => {
    if (typeof controller.teardown === 'function') {
      controller.teardown()
    }
  })
})
